import React, {useEffect, useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {Link, useSearchParams} from "react-router-dom";
import {
  getPaymentMethodsBillingSession,
  getPaymentMethodsBillingSessionManage
} from "../../../../services/APIService/Billing";

const PaymentMethods = () => {
  const [billingSessionUrl, setBillingSessionUrl] = useState('#')
  const [box, setBox] = useState("default")
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if(searchParams.get('error') === 'CARD_ON_FILE_REQUIRED'){
      getPaymentMethodsBillingSession()
        .then(res => {
          setBillingSessionUrl(res.data.url)
        })
      setBox('CARD_ON_FILE_REQUIRED')
    }else{
      getPaymentMethodsBillingSessionManage()
        .then(res => {
          setBillingSessionUrl(res.data.url)
        })
    }
  }, [searchParams]);

  return (
    <div className="w-full mx-3 dark:text-customGray-lightest">
      {
        box === 'default' ? <Card title="Payment Methods">
          <p className="text-sm">
            {process.env.REACT_APP_NAME} uses Stripe to securely manage your payment methods. <Link to={billingSessionUrl} className="text-customGreen underline">Launch Stripe Secure Billing Portal</Link>.
          </p>
        </Card> : null
      }
      {
        box === 'CARD_ON_FILE_REQUIRED' ? <Card title="No Card On File">
          <p className="text-sm">
            In order to upgrade or create more than one project, we need a card on file. <Link to={billingSessionUrl} className="text-customGreen underline">Click Here</Link> to add a payment method.
          </p>
          <p className="text-sm mt-3">
            <b>Note:</b> <br/>
            {process.env.REACT_APP_NAME} uses Stripe to securely manage your payment methods. <Link to={billingSessionUrl} className="text-customGreen underline">Launch Stripe Secure Billing Portal</Link>.
          </p>
        </Card> : null
      }
    </div>
  )
}

export default PaymentMethods